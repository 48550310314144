.impressum {
  padding: 100px 0 40px 70px;
  &-headline {
    font-size: 16px;
  }

  &-company {
    margin-bottom: 8px;
    font-size: 14px;
  }

  &-address {
    font-size: 14px;
  }

  @include breakpoint(medium) {
    margin-bottom: 40px;
    padding: 100px 100px 0;

    &-headline {
      font-size: 24px;
    }

    &-company {
      margin-bottom: 8px;
      font-size: 18px;
    }

    &-address {
      font-size: 18px;
    }
  }
}

.contact {
  padding: 0 0 40px 70px;

  &-headline {
    font-size: 16px;
  }

  &-phone {
    margin-bottom: 8px;
    font-size: 14px;
  }

  &-email {
    font-family: $font-family-sintony-regular;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 32px;
  }

  &-management {
    margin-bottom: 18px;
    font-size: 14px;
  }

  &-staff {
    font-family: $font-family-sintony-regular;
    font-size: 14px;
    list-style-type: initial;
    margin-left: 40px;
    margin-bottom: 32px;

    &-list {
      margin-bottom: 12px;
    }
  }

  &-register {
    font-size: 14px;
    &-data {
      font-size: 14px;
    }
  }

  @include breakpoint(medium) {
    padding: 0 100px 100px;
    &-headline {
      font-size: 24px;
    }

    &-phone {
      margin-bottom: 8px;
      font-size: 18px;
    }

    &-email {
      font-family: $font-family-sintony-regular;
      font-size: 18px;
      display: inline-block;
      margin-bottom: 32px;
    }

    &-management {
      margin-bottom: 18px;
      font-size: 18px;
    }

    &-staff {
      margin-left: 40px;
      margin-bottom: 32px;
      font-family: $font-family-sintony-regular;
      font-size: 18px;
      list-style-type: initial;

      &-list {
        margin-bottom: 12px;
        font-size: 18px;
      }
    }

    &-register {
      font-size: 18px;
      &-data {
        font-size: 18px;
      }
    }

  }
}
