// this generates classes like m-small or p-left-large

$sides: (top, bottom, left, right);

.m-auto {
  margin: 0 auto;
}

@each $s, $v in $space {
  .m-#{$s} {
    margin: space($s);
  }

  .p-#{$s} {
    padding: space($s);
  }

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$s} {
      margin-#{$side}: space($s);
    }

    .p#{str-slice($side, 0, 1)}-#{$s} {
      padding-#{$side}: space($s);
    }
  }
}
