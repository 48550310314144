$media-direction: "min";

@mixin breakpoint($breakpoint, $direction: $media-direction) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }
}
