.o-stage {
  padding: 0;
}

.o-stage__content {
  margin: 0;
  padding: 0;
  position: relative;
}

.stage-content {
  border-left: dashed 1px black;
  position: absolute;
  top: 10%;
  left: 10%;
  color: rgb(55, 55, 55);
  width: 100%;
  padding: 10px 30px;

  @include breakpoint(large) {
    padding: 50px 80px;
  }
}

.stage-heading {
  font-size: fontsize(body-small);
  @include breakpoint(large) {
    font-size: fontsize(h1);
  }
}

#WendecoverVideo {
  width: 100vw;
  left: 0;
  height: auto;
  display: block;
}

.video-button {
  margin-top: 2.5rem;
  background: rgb(180, 0, 1);
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  border: none;
}

.button-text {
  min-height: 20px;
  display: inline-flex;
  align-items: center;
}

.button-icon {
  margin-left: 8px;
}
