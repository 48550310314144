ul,
ol {
  list-style-type: none;
}

li {
  margin-bottom: 0.5rem;

  > ul,
  > ol {
    margin: 0;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}
