$font-family-sintony-regular: 'Sintony-Regular';
$font-family-sintony-bold: 'Sintony-Bold';

@font-face {
  font-family: $font-family-sintony-regular;
  src: url('../../static/font/Sintony-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-family-sintony-bold;
  src: url('../../static/font/Sintony-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
