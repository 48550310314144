.hamburger {
  position: fixed;
  z-index: 100;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.closeIcon {
  display: none;
}

.c-navigation {
  .menu {
    position: fixed;
    transform: translateY(-100%);
    transition: transform 0.2s;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.3) 0px -20px 30px;

    width: 90%;
    height: 100%;
    font-size: 18px;
    font-family: $font-family-sintony-regular;
    background-color: white;
    padding: 164px 0 0 50px;
    @include breakpoint(medium) {
      width: 75%;
      padding-left: 122px;
      font-size: 28px;
    }

    @include breakpoint(large) {
      width: 45%;
    }

    &.showMenu {
      transform: translateY(0);
    }

    .menuItem {
      display: inline-block;
      text-decoration: none;
      margin-bottom: space(large);
      color: color(gray-dark);
      &:active,
      &:focus {
        font-weight: bold;
      }
    }
  }

  .menu-backdrop {
    cursor: pointer;
    position: fixed;
    z-index: 98;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);

    transition: all 0.3s ease-in-out;

    visibility: hidden;
    opacity: 0;

    &.showMenu {
      visibility: visible;
      opacity: 1;
    }
  }
}
