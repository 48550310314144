.o-button {
  position: relative;
  cursor: pointer;
  padding: 12px;
  padding-right: 40px;
  border: none;
  background-color: color(red);
  color: color(white);
  font-family: $font-family-sintony-bold;
  font-size: 14px;
  text-decoration: none;

  &--chevron {
    padding-right: 40px;
    &:after {
      content: '';
      background-image: url('../../static/icons/noun_chevron_2492082.svg');
      background-repeat: no-repeat;
      background-size: 50%;
      position: absolute;
      top: 50%;
      right: 0;
      width: 16px;
      height: 16px;
      transform: translate(-30%, -50%);
    }
  }
}
