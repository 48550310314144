.c-footer {
  padding: space(medium);

  @include breakpoint(medium) {
    padding: space(2xl) space(3xlarge);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: color(gray);
    text-transform: uppercase;
    font-size: fontsize(body-small);
    font-family: $font-family-sintony-regular;

    @include breakpoint(medium) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__copyright {
      margin-bottom: space(large);
      font-size: 14px;
      @include breakpoint(medium) {
        font-size: fontsize(body-small);
        margin-bottom: 0;
      }
    }

    &__nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include breakpoint(medium) {
        flex-direction: row;
        justify-content: flex-start;
      }

      &__item {
        color: inherit;
        text-decoration: none;

        font-size: 12px;
        @include breakpoint(medium) {
          font-size: fontsize(body-small);
        }

        &:not(:last-of-type) {
          margin-bottom: space(small);
          @include breakpoint(medium) {
            margin-bottom: 0;
            margin-right: space(large);
          }
        }
      }
    }
  }
}
