.c-fact-carousel {
  position: relative;

  &__wrapper {
    padding-left: space(large);
    padding-right: space(xlarge);
    @include breakpoint(medium) {
      padding-left: space(2large);
      padding-right: space(2xlarge);
    }
    @include breakpoint(large) {
      padding-left: space(3xlarge);
      padding-right: space(3xlarge);
    }
  }

  .fact {
    margin-bottom: space(large);
    font-size: 14px;

    &__copy {
      font-size: fontsize(body-small);

      @include breakpoint(large) {
        font-size: fontsize(body-large);
      }
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    text-align: center;

    @include breakpoint(large) {
      flex-direction: row;
      text-align: left;
    }

    .icon {
      width: 25%;
      margin: 0 auto;
      margin-bottom: space(large);

      @include breakpoint(medium) {
        width: 15%;
      }

      @include breakpoint(large) {
        margin: 0;
        margin-right: space(3xlarge);
        width: auto;
      }
    }
  }
}

#fact-carousel-button-prev,
#fact-carousel-button-next {
  cursor: pointer;
  position: absolute;
  top: 20%;
  width: 8px;

  @include breakpoint(medium) {
    top: 30%;
    width: 12px;
  }

  @include breakpoint(large) {
    width: auto;
    top: 50%;
  }
}

#fact-carousel-button-prev {
  transform: translateY(-50%) rotate(180deg);
  left: 5%;

  @include breakpoint(large) {
    transform: translateY(-50%) translateX(-200%) rotate(180deg);
  }
}

#fact-carousel-button-next {
  transform: translateY(-50%);
  right: 5%;

  @include breakpoint(large) {
    transform: translateY(-50%) translateX(200%);
  }
}
