html {
  scroll-behavior: smooth;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

/**
   * Remove trailing margins from nested lists.
   */

li > {
  ol,
  ul {
    margin-bottom: 0;
  }
}

/**
   * Remove default table spacing.
   */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
   * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
   *    on fieldsets.
   */

fieldset {
  min-width: 0; /* [1] */
  border: 0;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
}

// Remove scrollbars

::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 6px;
}

// prevent hightlight color
html,
* {
  &,
  &:before,
  &:after {
    -webkit-tap-highlight-color: transparent;
  }
}

html,
body,
#root {
  min-height: 100%;
  user-select: none;

  @include breakpoint(medium) {
    min-width: 100%;
  }
}

#root {
  position: relative;
  overflow-x: hidden;
}

.SVGInline {
  font-size: 0;
}
