.o-container {
  $self: &;

  position: relative;
  overflow-x: hidden;
  width: 100%;

  padding: space(medium);

  @include breakpoint(medium) {
    padding: space(3xlarge);
  }

  &-color {
    @each $key, $value in $colors {
      &--#{'' + $key} {
        background-color: color($key);
      }
    }
  }

  &__content {
    position: relative;
    margin: 0 auto;

    max-width: 1024px;

    padding-top: space(2xlarge);
    padding-bottom: space(2xlarge);

    @include breakpoint(xlarge) {
      max-width: 1280px;
    }
  }
}
