.dataprotection {
  padding: 100px 30px 40px 70px;
  &-headline {
    font-size: 16px;
  }

  &-copy {
    margin-bottom: 32px;
    font-size: 14px;
  }

  &-company {
    font-size: 14px;
  }

  &-address {
    margin-bottom: 32px;
    font-size: 14px;
  }

  &-responsable {
    font-size: 14px;
  }

  &-email {
    font-family: $font-family-sintony-regular;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 20px;
  }

  @include breakpoint(medium) {
    margin-bottom: 40px;
    padding: 100px 100px 0;
    max-width: 768px;
    margin-right: auto;

    &-headline {
      font-size: 24px;
    }

    &-copy {
      font-size: 16px;
    }

    &-company {
      font-size: 18px;
    }

    &-address {
      font-size: 18px;
    }

    &-responsable {
      font-size: 18px;
    }

    &-email {
      font-size: 18px;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
