.u-text {
  font-family: $font-family-sintony-regular;

  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-bold {
    font-family: 'bold';
    font-weight: bold;
  }

  &-medium {
    font-family: 'medium';
    font-weight: 500;
  }

  &-size {
    @each $key, $value in $font-size {
      &--#{$key} {
        font-size: fontsize($key);
      }
    }
  }

  &-color {
    @each $key, $value in $colors {
      &--#{'' + $key} {
        color: color($key);
      }
    }
  }
}
