.c-present {
  padding-top: 55px;

  @include breakpoint(medium) {
    top: space(medium);
  }

  @include breakpoint(large) {
    top: space(3xlarge);
  }

  &__wrapper {
    position: relative;
    background-image: url('../../static/img/Falz-Outline.png');
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: -570px 145px;

    @include breakpoint(medium) {
      background-size: 80%;
      background-position: -850px 335px;
    }
  }

  &__dashline {
    position: absolute;
    top: space(xlarge);
    left: 0;

    width: 50%;
    border: 1px dashed color(black);

    @include breakpoint(medium) {
      width: 35%;
      top: space(3xlarge);
    }
  }

  &__headline {
    width: 100%;
    @include breakpoint(medium) {
      width: 70%;
    }
    @include breakpoint(large) {
      width: 50%;
    }
  }

  &__copy {
    width: 100%;
    margin-bottom: space(large);
    @include breakpoint(medium) {
      width: 70%;
      margin-bottom: space(xlarge);
    }
    @include breakpoint(large) {
      width: 50%;
      margin-bottom: space(3xlarge);
    }
  }

  &__carousel {
    .swiper-slide {
      padding: space(xlarge);
      background-color: color(red);
      height: auto;
    }

    h3 {
      text-transform: uppercase;
    }

    &-button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 2.5rem;
      margin-right: 10%;
    }
  }

  #c-present__carousel-button-prev,
  #c-present__carousel-button-next {
    cursor: pointer;
    text-align: center;
    width: 43px;
    height: 43px;
    border: 2px solid color(red);
    margin-left: 25px;
    display: none;
    justify-content: center;
    align-items: center;

    img {
      height: 25px;
    }

    @include breakpoint(small) {
      display: flex;
    }
  }

  #c-present__carousel-button-prev {
    transform: rotate(180deg);
  }

  #c-present__carousel-button-next {
  }
}

.swiper-button-disabled {
  opacity: 20%;
}
