h1 {
  color: color(gray-dark);
  font-size: fontsize(h1-small);
  line-height: 1.5;
  font-family: $font-family-sintony-bold;
  font-weight: bold;

  @include breakpoint(medium) {
    font-size: fontsize(h1);
  }
}

h2 {
  color: color(gray-dark);
  font-size: fontsize(h2-small);
  line-height: 1.5;
  font-family: $font-family-sintony-bold;
  font-weight: bold;
  margin-bottom: 24px;

  @include breakpoint(medium) {
    font-size: fontsize(h2);
  }
}

h3 {
  color: color(gray-dark);
  font-size: fontsize(h3-small);
  line-height: 1.4;
  font-family: $font-family-sintony-bold;
  font-weight: bold;
  margin-bottom: space(small);
  @include breakpoint(medium) {
    margin-bottom: space(medium);
    font-size: fontsize(h3);
  }
}
