.c-textimg {
  @include breakpoint(large) {
    display: flex;
    flex-direction: row;
  }

  &__img {
    width: 100%;
    margin-bottom: space(large);
    text-align: center;

    img {
      width: 80%;
    }

    @include breakpoint(medium) {
      margin-bottom: space(xlarge);
      img {
        width: 70%;
      }
    }

    @include breakpoint(large) {
      width: 40%;
      margin-bottom: 0;
      text-align: right;
      padding-right: space(xlarge);

      img {
        margin-top: 10%;
        height: 80%;
      }
    }
  }

  &__text {
    width: 100%;

    position: relative;

    padding-left: space(large);

    @include breakpoint(medium) {
      padding-left: space(xlarge);
    }

    @include breakpoint(large) {
      width: 60%;
      padding-left: space(3xlarge);
    }

    &__dashline {
      position: absolute;
      left: 0;
      top: 0;
      height: 40%;
      border: 1px dashed color(white);
    }
  }
}
